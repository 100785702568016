<template>
  <div
    class="authentication-form bg-white flex flex-col flex-grow justify-center py-12 sm:px-6 lg:px-8"
  >
    <router-link to="/">
      <img
        class="mx-auto h-12 w-auto mb-4"
        alt="SongPulse Logo"
        src="@/assets/logo.svg"
      />
    </router-link>
    <h1 class="main-title">{{ title }}</h1>
    <div class="mt-8 mx-5 sm:mx-auto sm:w-full sm:max-w-md">
      <div
        class="bg-white py-8 px-4 shadow rounded-lg sm:px-10 border border-gray-100"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuthenticationFormContainer',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
