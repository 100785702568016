<template>
  <AuthenticationFormContainer title="Forgot your Password?">
    <form class="space-y-6" method="post" @submit="submitForm">
      <div class="field">
        <label for="email">Email</label>
        <div class="mt-1">
          <input
            id="email"
            v-model="inputEmail"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            autofocus
            :disabled="isLoading || alreadyRequestedReset"
          />
        </div>
      </div>
      <pre v-if="error">{{ error }}</pre>
      <CustomAlert
        v-if="alreadyRequestedReset"
        text="Check your inbox, we've sent you an email with instructions to reset your password"
        type="info"
      />
      <div class="field">
        <button
          type="submit"
          class="btn btn-primary full-width"
          :disabled="isLoading || alreadyRequestedReset"
        >
          <div
            v-if="isLoading"
            style="border-top-color: transparent"
            class="w-4 h-4 mr-2 border-2 border-blue-400 border-solid rounded-full animate-spin"
          />
          Request a new Password
        </button>
      </div>
    </form>
    <hr class="my-7" />
    <div class="text-sm">
      <router-link
        :to="{ name: 'login' }"
        class="font-medium text-gray-500 hover:text-gray-600"
      >
        Log In
      </router-link>
      <router-link :to="{ name: 'register' }" class="float-right">
        Not registered yet?
      </router-link>
    </div>
  </AuthenticationFormContainer>
</template>
<script>
import Auth from '@/services/auth/Auth';
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'ForgotPasswordPage',
  components: { CustomAlert, AuthenticationFormContainer },
  data() {
    return {
      inputEmail: '',
      isLoading: false,
      alreadyRequestedReset: false,
      error: null,
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      this.isLoading = true;

      try {
        await Auth.requestNewPassword({ email: this.inputEmail });
        this.alreadyRequestedReset = true;
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
