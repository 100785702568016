<template>
  <div
    id="app"
    :class="{ 'bg-gray-100': !isLandingOrTermsPage && isAuthenticated }"
  >
    <ProgressBar />
    <div class="flex flex-col min-h-screen">
      <div
        :class="{
          'flex flex-col flex-grow w-full px-6 pb-12 antialiased bg-white mx-auto max-w-7xl':
            isLandingOrTermsPage,
          'flex flex-col flex-grow': !isLandingOrTermsPage,
        }"
      >
        <NavigationDark v-if="showDarkNavigation" />
        <NavigationBright v-if="showBrightNavigation" />

        <template v-if="isAuthenticated && !isLandingOrTermsPage">
          <DashboardContentWrapper :title="pageTitle">
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </DashboardContentWrapper>
        </template>
        <template v-else>
          <router-view />
        </template>
      </div>
      <CustomFooter />
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar';
import CustomFooter from '@/components/CustomFooter';
import NavigationBright from '@/components/NavigationBright';
import NavigationDark from '@/components/NavigationDark';
import DashboardContentWrapper from '@/components/dashboard/DashboardContentWrapper';
export default {
  components: {
    DashboardContentWrapper,
    NavigationDark,
    NavigationBright,
    CustomFooter,
    ProgressBar,
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
    currentRouteName() {
      return this.$route.name;
    },
    isLandingOrTermsPage() {
      return (
        this.currentRouteName === 'landing' || this.currentRouteName === 'terms'
      );
    },
    showBrightNavigation() {
      return this.isLandingOrTermsPage;
    },
    showDarkNavigation() {
      return this.isAuthenticated && !this.isLandingOrTermsPage;
    },
    pageTitle() {
      if (this.currentRouteName === 'dashboard') {
        return 'Dashboard';
      } else if (this.currentRouteName === 'settings') {
        return 'Settings';
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-slide-top-enter-active,
.fade-slide-top-leave-active {
  transition: all 0.5s;
}
.fade-slide-top-enter,
.fade-slide-top-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
.fade-slide-left-enter-active,
.fade-slide-left-leave-active {
  transition: all 0.5s;
}
.fade-slide-left-enter,
.fade-slide-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
