import Vue from 'vue';
import Vuex from 'vuex';
import SelfReportService from '@/services/SelfReportService';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    isAuthenticated: false,
    selfReportDtos: null,
    selfReportDataByQuestion: null,
    heartRateData: null,
  },
  getters: {},
  mutations: {
    authorizeUser: (state, user) => {
      state.user = user;
      state.isAuthenticated = user !== null;
    },
    setSelfReportDataByQuestion: (state, data) => {
      state.selfReportDataByQuestion = data;
    },
    setSelfReportDtos: (state, data) => {
      state.selfReportDtos = data;
    },
    setHeartRateData: (state, data) => {
      state.heartRateData = data;
    },
    resetData: (state) => {
      state.selfReportDataByQuestion = null;
      state.heartRateData = null;
    },
  },
  actions: {
    fetchSelfReportData: async ({ state, commit }, { start, end }) => {
      const response = await SelfReportService.getSelfReportData(
        state.user.id,
        start,
        end
      );
      commit('resetData');
      commit(
        'setSelfReportDataByQuestion',
        response.data.selfReportsByQuestion
      );
      commit('setSelfReportDtos', response.data.selfReports);
      commit('setHeartRateData', Object.values(response.data.heartRates));
    },
  },
  modules: {},
});
