<template>
  <nav class="relative z-50 h-24 select-none">
    <div
      class="container relative flex flex-wrap items-center justify-between h-24 mx-auto overflow-hidden font-medium border-b border-gray-200 md:overflow-visible lg:justify-center sm:px-4 md:px-2"
    >
      <div class="flex items-center justify-start w-1/3 md:w-1/5 h-full pr-4">
        <a href="#" class="inline-block py-4 md:py-0">
          <img src="@/assets/logo.svg" alt="SongPulse Logo" />
        </a>
      </div>
      <div
        class="top-0 left-0 items-start w-full h-full p-4 text-sm bg-gray-900 bg-opacity-50 md:items-center md:w-3/4 md:absolute lg:text-base md:bg-transparent md:p-0 md:relative md:flex"
        :class="{ 'flex fixed': showMenu, hidden: !showMenu }"
      >
        <div
          class="flex-col w-full h-auto overflow-hidden bg-white rounded-lg md:bg-transparent md:overflow-visible md:rounded-none md:relative md:flex md:flex-row"
        >
          <a
            href="#"
            class="inline-flex items-center w-1/3 h-full block w-auto h-16 py-10 px-6 text-xl font-black leading-none text-gray-900 md:hidden"
            ><img src="@/assets/logo.svg" class="w-auto" alt="SongPulse Logo"
          /></a>
          <div
            class="flex flex-col items-start justify-center w-full space-x-6 text-center lg:space-x-8 md:w-2/3 md:mt-0 md:flex-row md:items-center"
          ></div>
          <div
            class="flex flex-col items-start justify-end w-full p-2 md:items-center md:w-1/3 md:flex-row md:py-0"
          >
            <router-link
              to="/login"
              class="btn btn-secondary btn-no-outline px-6 py-2 mr-0 mb-2 sm:mb-0 text-gray-700 w-full sm:text-center md:mr-4 lg:mr-5 md:w-auto md:rounded-full"
              >Sign In</router-link
            >
            <router-link
              to="/register"
              class="btn btn-primary md:rounded-full w-full text-center md:w-auto"
              >Sign Up</router-link
            >
          </div>
        </div>
      </div>
      <div
        class="absolute right-0 flex flex-col items-center items-end justify-center w-10 h-10 bg-white rounded-md cursor-pointer md:hidden hover:bg-gray-100"
        @click="showMenu = !showMenu"
      >
        <span class="sr-only">Open main menu</span>
        <font-awesome-icon
          v-if="!showMenu"
          icon="fa-regular fa-bars"
          class="block fa-w-16 h-6 w-6 text-gray-700"
        />
        <font-awesome-icon
          v-if="showMenu"
          icon="fa-regular fa-circle-xmark"
          class="block fa-w-16 h-6 w-6 text-gray-700"
        />
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBright',
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style scoped lang="scss"></style>
