<template>
  <AuthenticationFormContainer v-if="!loginSuccess" title="Sign In">
    <form class="space-y-6" method="post" @submit="submitForm">
      <CustomAlert
        v-if="hasResetPasswordSuccessfully"
        type="success"
        text="Successfully set new password. You can now log in with your new password."
      />
      <div class="field">
        <label for="username">E-Mail or Username</label>
        <div class="mt-1">
          <input
            id="email"
            v-model="inputUsernameOrEmail"
            name="email"
            type="text"
            autocomplete="email"
            required=""
            autofocus
          />
        </div>
      </div>

      <div class="field">
        <label for="password">Password</label>
        <div class="mt-1">
          <input
            id="password"
            v-model="inputPassword"
            name="password"
            type="password"
            autocomplete="password"
            required=""
          />
        </div>
      </div>
      <CustomAlert v-if="error" :text="error" />
      <div class="field">
        <button type="submit" class="btn btn-primary full-width relative">
          Sign In
          <div
            v-if="isLoading"
            style="border-top-color: transparent"
            class="w-4 h-4 right-4 border-2 border-blue-400 border-solid rounded-full absolute animate-spin"
          />
        </button>
      </div>
    </form>
    <hr class="my-7" />
    <div class="text-sm">
      <router-link
        :to="{ name: 'forgot-password' }"
        class="font-medium text-gray-500 hover:text-gray-600"
      >
        Forgot password?
      </router-link>
      <router-link to="register" class="float-right">
        Not registered yet?
      </router-link>
    </div>
  </AuthenticationFormContainer>
</template>
<script>
import Auth from '@/services/auth/Auth';
import Store from '@/store';
import Router from '@/router';
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'LoginPage',
  components: { CustomAlert, AuthenticationFormContainer },
  data() {
    return {
      inputUsernameOrEmail: '',
      inputPassword: '',
      isLoading: false,
      loginSuccess: false,
      error: null,
    };
  },
  computed: {
    hasResetPasswordSuccessfully() {
      return this.$route.query.resetPassword;
    },
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      this.isLoading = true;

      try {
        await Auth.login({
          usernameOrEmail: this.inputUsernameOrEmail,
          password: this.inputPassword,
        });
        this.loginSuccess = true;
        const account = await Auth.getAccountAndUpdateStore();
        Store.commit('authorizeUser', account.data);
        Router.push('dashboard');
      } catch (e) {
        this.error = e.response.data.message;
        this.isLoading = false;
        this.loginSuccess = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.registration {
}
</style>
