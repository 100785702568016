<template>
  <div class="flex items-center">
    <div
      :class="[
        enabled ? 'bg-blue-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      ]"
      @click="onSwitchClicked"
    >
      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      />
    </div>
    <span class="ml-3 text-sm font-medium text-gray-900">Step Chart </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enabled: this.value,
    };
  },
  methods: {
    onSwitchClicked() {
      this.enabled = !this.enabled;
      this.$emit('input', this.enabled);
    },
  },
};
</script>
