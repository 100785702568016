<template>
  <AuthenticationFormContainer title="Set a new password">
    <form class="space-y-6" method="post" @submit="submitForm">
      <div class="field">
        <label for="newPassword">New password</label>
        <div class="mt-1">
          <input
            id="newPassword"
            v-model="inputNewPassword"
            name="newPassword"
            type="password"
            autocomplete="new-password"
            required
            autofocus
            :disabled="isLoading"
          />
          <small class="text-gray-300">(minimum 6 characters)</small>
        </div>
      </div>
      <div class="field">
        <label for="confirmPassword">Confirm new password</label>
        <div class="mt-1">
          <input
            id="confirmPassword"
            v-model="inputConfirmPassword"
            name="confirmPassword"
            type="password"
            autocomplete="new-password"
            required
            autofocus
            :disabled="isLoading"
          />
        </div>
      </div>
      <CustomAlert v-if="error" class="mb-2" type="error" :text="error" />
      <div class="field">
        <button
          type="submit"
          class="btn btn-primary full-width"
          :disabled="isLoading || !passwordsMatchAndValid || !token"
        >
          <div
            v-if="isLoading"
            style="border-top-color: transparent"
            class="w-4 h-4 mr-2 border-2 border-blue-400 border-solid rounded-full animate-spin"
          />
          Set new password
        </button>
      </div>
    </form>
    <hr class="my-7" />
    <div class="text-sm">
      <router-link
        :to="{ name: 'login' }"
        class="font-medium text-gray-500 hover:text-gray-600"
      >
        Log In
      </router-link>
      <router-link :to="{ name: 'register' }" class="float-right">
        Not registered yet?
      </router-link>
    </div>
  </AuthenticationFormContainer>
</template>
<script>
import Auth from '@/services/auth/Auth';
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert.vue';
export default {
  name: 'ResetPasswordPage',
  components: { CustomAlert, AuthenticationFormContainer },
  data() {
    return {
      inputNewPassword: '',
      inputConfirmPassword: '',
      isLoading: false,
      error: null,
    };
  },
  computed: {
    passwordsMatchAndValid() {
      if (this.inputNewPassword.length < 6) {
        return false;
      }
      return this.inputNewPassword === this.inputConfirmPassword;
    },
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    async submitForm(e) {
      this.error = null;
      if (this.passwordsMatchAndValid === false) {
        return;
      }
      e.preventDefault();
      this.isLoading = true;
      try {
        await Auth.resetPassword(this.token, this.inputNewPassword);
      } catch (e) {
        this.error = e.response.data.message;
      }
      this.isLoading = false;
      this.$router.push({ name: 'login', query: { resetPassword: true } });
    },
  },
};
</script>

<style scoped lang="scss"></style>
