<template>
  <div
    class="rounded-md p-4"
    :class="{
      'bg-red-50': type === 'error',
      'bg-green-50': type === 'success',
      'bg-blue-50': type === 'info',
    }"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <font-awesome-icon
          v-if="type === 'error'"
          icon="fa-regular fa-circle-xmark"
          class="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
        <font-awesome-icon
          v-if="type === 'success'"
          icon="fa-regular fa-circle-check"
          class="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
        <font-awesome-icon
          v-if="type === 'info'"
          icon="fa-regular fa-info-circle"
          class="h-5 w-5 text-blue-500"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <h3
          v-if="title"
          class="text-sm font-medium mb-2"
          :class="{
            'text-red-800': type === 'error',
            'text-green-800': type === 'success',
            'text-blue-800': type === 'info',
          }"
        >
          {{ title }}
        </h3>
        <p
          class="text-sm"
          :class="{
            'text-red-700': type === 'error',
            'text-green-700': type === 'success',
            'text-blue-500': type === 'info',
          }"
        >
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomAlert',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'error',
    },
  },
};
</script>

<style scoped lang="scss"></style>
