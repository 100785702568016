<template>
  <div class="temporal-chart">
    <div class="chart-wrapper">
      <a
        class="mt-3 text-sm text-blue-500 cursor-pointer hover:underline hover:text-blue-700 float-right"
        @click="downloadRawSelfReportDataWithoutHeartRate"
      >
        <font-awesome-icon
          icon="fa-regular fa-file-csv"
          class="h-4 w-4"
          aria-hidden="true"
        />
        Export single question data</a
      >
      <h3 class="font-medium text-xl">
        Temporal Chart <span class="text-xs"> by weekday</span>
      </h3>
      <div
        v-for="(weekdayData, weekdayIndex) in groupedDataByDateAndWeekday"
        :key="weekdayIndex"
        class="weekday-wrapper"
      >
        <h4
          class="mb-1 mt-4"
          :class="{ 'text-gray-400': weekdayData.data.length === 0 }"
        >
          {{ weekdayData.weekdayName }}
        </h4>
        <div v-if="!weekdayData.data" class="empty-weekday">
          <div class="text-sm text-gray-400">No data</div>
        </div>
        <div
          v-for="(singleWeekday, idx) in weekdayData.data"
          :key="idx"
          class="date-entry mb-3"
        >
          <div
            v-for="(singleDateDataPoint, dateIndex) in singleWeekday"
            :key="dateIndex"
            class="single-data-point"
            :style="{
              left: `${singleDateDataPoint.position}%`,
              'background-color':
                selfReportValueToColor[singleDateDataPoint.value],
            }"
          ></div>
        </div>
      </div>
      <div class="chart-labels-hours text-xs mt-4 mb-8">
        <div
          v-for="hour in 24"
          :key="hour"
          class="position-absolute float-left"
          :style="{ left: `${(hour / 24) * 100}%`, width: `${100 / 24}%` }"
        >
          {{ hour - 1 }}
        </div>
      </div>
    </div>
    <div class="chart-wrapper">
      <h3 class="font-medium text-xl">
        Temporal Chart <span class="text-xs"> by date</span>
      </h3>
      <div
        v-for="dateData in groupedDataByDateString"
        :key="dateData.date"
        class="weekday-wrapper"
      >
        <h4
          class="absolute w-24 -right-28 -mt-2 text-left invisible md:visible"
        >
          {{ dateData.date }}
        </h4>

        <div class="date-entry mb-4">
          <div
            v-for="(singleDateDataPoint, dateIndex) in dateData.data"
            :key="dateIndex"
            class="single-data-point"
            :style="{
              left: `${singleDateDataPoint.position}%`,
              'background-color':
                selfReportValueToColor[singleDateDataPoint.value],
            }"
          />
        </div>
      </div>
      <div class="chart-labels-hours text-xs mt-4 mb-14">
        <div
          v-for="hour in 24"
          :key="hour"
          class="position-absolute float-left"
          :style="{ left: `${(hour / 24) * 100}%`, width: `${100 / 24}%` }"
        >
          {{ hour - 1 }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';
import { arrayToCsv, downloadCsv } from '@/services/CsvHelper';

export default {
  name: 'TemporalChart',
  props: {
    data: {
      type: Array,
      default: null,
    },
    question: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selfReportValueToColor: {
        0: '#9b9b9b',
        1: '#1f6700',
        2: '#679901',
        3: '#00ce00',
        4: '#fffb00',
        5: '#ffbb00',
        6: '#ff7300',
        7: '#ff0000',
      },
    };
  },
  computed: {
    userTimeZone() {
      return this.$store.state.user.timezone;
    },
    groupedDataByDateString() {
      const groupedDataByDateString = {};
      for (const dataPoint of this.data) {
        const date = DateTime.fromISO(dataPoint.date, {
          zone: this.userTimeZone,
        });
        const dateString = date.toISODate();
        const hour = date.hour;
        const minute = date.minute;
        const hours = hour + minute / 60;
        const position = (hours / 24) * 100;
        if (!groupedDataByDateString[dateString]) {
          groupedDataByDateString[dateString] = [];
        }
        groupedDataByDateString[dateString].push({
          position,
          ...dataPoint,
        });
      }
      const result = [];
      for (const [dateString, dataPoints] of Object.entries(
        groupedDataByDateString
      )) {
        result.push({
          isoDate: dateString,
          date: DateTime.fromISO(dateString).toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          data: dataPoints,
        });
      }
      // sort result by date
      result.sort((a, b) => {
        const dateA = DateTime.fromISO(a.isoDate);
        const dateB = DateTime.fromISO(b.isoDate);
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    groupedDataByDateAndWeekday() {
      const groupedByWeekdayAndDate = [
        { weekdayName: 'Monday', data: {} },
        { weekdayName: 'Tuesday', data: {} },
        { weekdayName: 'Wednesday', data: {} },
        { weekdayName: 'Thursday', data: {} },
        { weekdayName: 'Friday', data: {} },
        { weekdayName: 'Saturday', data: {} },
        { weekdayName: 'Sunday', data: {} },
      ];
      for (const dateStringData of this.groupedDataByDateString) {
        const dateString = dateStringData.isoDate;
        const date = DateTime.fromISO(dateString);
        const zeroBasedWeekdayNumber = date.weekday - 1;
        if (!groupedByWeekdayAndDate[zeroBasedWeekdayNumber].data[dateString]) {
          groupedByWeekdayAndDate[zeroBasedWeekdayNumber].data[dateString] = [];
        }
        groupedByWeekdayAndDate[zeroBasedWeekdayNumber].data[dateString].push(
          ...this.groupedDataByDateString.find((d) => d.isoDate === dateString)
            ?.data
        );
      }
      const result = [];
      for (const weekday of groupedByWeekdayAndDate) {
        const weekdayName = weekday.weekdayName;
        const data = [];
        for (const [dateString, dataPoints] of Object.entries(weekday.data)) {
          data.push({
            date: dateString,
            data: dataPoints,
          });
        }
        result.push({
          weekdayName,
          data,
        });
      }
      return groupedByWeekdayAndDate;
    },
  },
  methods: {
    downloadRawSelfReportDataWithoutHeartRate() {
      const csvHeader = ['completedOnDevice', 'answer'];
      const csvResult = [csvHeader];
      csvResult.push(...this.data.map((d) => [d.date, d.value]));

      downloadCsv(
        arrayToCsv(csvResult),
        `songpulse_export-${this.question}.csv`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.weekday-wrapper {
  position: relative;
  .date-entry {
    position: relative;
    height: 5px;
    &::before {
      display: inline-block;
      content: '';
      border-top: 5px solid #d1d1d1;
      border-radius: 5px;
      position: absolute;
      width: 100%;
    }

    .single-data-point {
      width: 7px;
      height: 7px;
      border-radius: 5px;
      position: absolute;
      top: -1px;
    }
  }
}
.chart-labels-hours {
  @apply h-2;
}
</style>
