import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '@/services/auth/Auth';
import DashboardPage from '@/pages/DashboardPage';
import SettingsPage from '@/pages/SettingsPage';
import LandingPage from '@/pages/LandingPage';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage';
import LoginPage from '@/pages/LoginPage';
import RegistrationPage from '@/pages/RegistrationPage';
import EmailVerificationPage from '@/pages/EmailVerificationPage';
import TermsPage from '@/pages/TermsPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage.vue';

Vue.use(VueRouter);

const requireAuthentication = (_to, _from, next) => {
  if (Auth.isAuthenticated()) {
    next();
  } else {
    next({ name: 'login' });
  }
};

const requireNoAuthentication = (_to, _from, next) => {
  if (Auth.isAuthenticated()) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
  },
  {
    path: '/register',
    name: 'register',
    beforeEnter: requireNoAuthentication,
    props: (route) => ({ inviteCode: route.query.c }),
    component: RegistrationPage,
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: requireNoAuthentication,
    component: LoginPage,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: requireAuthentication,
    component: DashboardPage,
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: requireAuthentication,
    component: SettingsPage,
  },
  {
    path: '/auth/forgot',
    name: 'forgot-password',
    beforeEnter: requireNoAuthentication,
    component: ForgotPasswordPage,
  },
  {
    path: '/auth/password/reset/:token',
    name: 'reset-password',
    beforeEnter: requireNoAuthentication,
    component: ResetPasswordPage,
  },
  {
    path: '/auth/email/verify/:token',
    name: 'verify-email',
    component: EmailVerificationPage,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage,
  },
  { path: '*', redirect: '/dashboard' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((_to, _from, next) => {
  Promise.all([Auth.getAccountAndUpdateStore()]).then(() => {
    next();
  });
});

export default router;
