import Http from '@/config/Http';

const SelfReportService = {
  async getSelfReportData(userId, start, end) {
    return Http.get(`users/${userId}/self-reports`, {
      params: {
        start,
        end,
      },
    });
  },
};

export default SelfReportService;
