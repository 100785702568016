<template>
  <AuthenticationFormContainer title="Sign Up For Free">
    <CustomAlert
      v-if="errors.length > 0"
      class="mb-2"
      type="error"
      :text="backendValidationErrorMessage"
    />

    <form class="space-y-6" method="post" @submit="submitForm">
      <div class="field">
        <label for="email">Email</label>
        <div class="mt-1">
          <input
            id="email"
            v-model="inputEmail"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            autofocus
          />
        </div>
      </div>
      <div class="field">
        <label for="username">Username</label>
        <div class="mt-1">
          <input
            id="username"
            v-model="inputUsername"
            name="username"
            type="text"
            autocomplete="username"
            required=""
          />
        </div>
      </div>

      <div class="field">
        <label for="password">Password</label>
        <div class="mt-1">
          <input
            id="password"
            v-model="inputPassword"
            name="password"
            type="password"
            autocomplete="new-password"
            required=""
          />
          <small class="text-gray-300">(minimum 6 characters)</small>
        </div>
      </div>

      <div class="field">
        <label for="password_confirmation">Password Confirmation</label>
        <div class="mt-1">
          <input
            id="password_confirmation"
            v-model="inputPasswordConfirmation"
            name="password_confirmation"
            type="password"
            autocomplete="new-password"
            required=""
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <input
            id="study-consent"
            v-model="studyConsentGiven"
            name="study-consent"
            type="checkbox"
            required=""
          />
          <label for="study-consent" class="ml-2 block text-sm text-gray-900">
            I have read and agree to the Study Terms
          </label>
        </div>
      </div>

      <div class="rounded-md bg-gray-100 border border-gray-200 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <font-awesome-icon
              icon="fa-regular fa-info-circle"
              class="fa-w-16 h-5 w-5 text-gray-400"
            />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-gray-700">
              We're currently running a
              <router-link to="terms" target="_blank" class="underline"
                >study</router-link
              >
              to evaluate SongPulse. Signing up is only possible, if you agree
              to participate in it and have a invitation code.
            </p>
          </div>
        </div>
      </div>
      <div class="field">
        <label
          for="invitation-code"
          class="block text-sm font-medium text-gray-700"
          >Invite Code</label
        >
        <div class="mt-1 relative">
          <input
            id="invitation-code"
            v-model="inputInvitationCode"
            :disabled="inviteCode"
            name="invitation-code"
            type="text"
            required=""
            class="disabled:text-gray-300 disabled:cursor-not-allowed disabled:select-none"
          />
          <div
            v-if="inviteCode"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            <font-awesome-icon
              icon="fa-regular fa-circle-check"
              class="fa-w-16 h-5 w-5 text-green-600"
            />
          </div>
        </div>
      </div>

      <div class="field">
        <button type="submit" class="btn btn-primary full-width">
          Sign Up
        </button>
      </div>
    </form>
    <hr class="my-7" />
    <div class="text-sm">
      <router-link to="login">Already signed up?</router-link>
    </div>
  </AuthenticationFormContainer>
</template>
<script>
import Auth from '@/services/auth/Auth';
import Store from '@/store';
import Router from '@/router';
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'RegistrationPage',
  components: { CustomAlert, AuthenticationFormContainer },
  props: {
    inviteCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputEmail: '',
      inputUsername: '',
      inputPassword: '',
      inputPasswordConfirmation: '',
      inputInvitationCode: this.inviteCode || '',
      studyConsentGiven: false,
      submitting: false,
      backendValidationErrorMessage: '',
      errors: [],
      fieldToStringMap: {
        email: 'Email',
        username: 'Username',
        password: 'Password',
        password_confirmation: 'Password Confirmation',
        studyConsent: 'Study Consent',
        invitationCode: 'Invitation Code',
      },
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      this.submitting = true;
      this.errors = [];
      this.backendValidationErrorMessage = '';

      if (this.inputPassword !== this.inputPasswordConfirmation) {
        // TODO: show error
      } else {
        try {
          await Auth.registerUser({
            username: this.inputUsername,
            email: this.inputEmail,
            password: this.inputPassword,
            timezone: 'Europe/Zurich',
            invitationCode: this.inputInvitationCode,
            studyConsentGiven: this.studyConsentGiven,
          });
          const account = await Auth.getAccountAndUpdateStore();
          Store.commit('authorizeUser', account.data);
          Router.push('dashboard');
        } catch (err) {
          const data = err.response.data;
          if (data.error) {
            this.errors = err.response.data.message;
          }
          if (data.errors) {
            this.errors = data.errors;
          }
          if (this.errors) {
            for (const error of this.errors) {
              if (typeof error === 'string') {
                this.backendValidationErrorMessage += `${error}\n`;
              } else {
                if (error.field && error.message) {
                  this.backendValidationErrorMessage += `${
                    this.fieldToStringMap[error.field]
                  }: ${error.message}\n`;
                }
              }
            }
          }
        }
      }
    },
  },
};
</script>
