import Http from '@/config/Http';
import Store from '@/store';
import Router from '@/router';

const Auth = {
  async login(credentials) {
    return Http.post('/auth/login', credentials);
  },

  async logout() {
    Store.commit('authorizeUser', null);
    try {
      await Http.get('auth/logout');
    } catch (e) {
      // We just don't want this to throw an error
    }
    Router.push({ name: 'landing' });
  },

  async registerUser(dto) {
    return Http.post('auth/register', dto, { withCredentials: true });
  },

  async requestNewPassword(emailDto) {
    return Http.post('/auth/password/forgot', emailDto);
  },

  async verifyEmailAddress(verifyEmailTokenDto) {
    return Http.get(`/auth/email/verify/${verifyEmailTokenDto}`);
  },

  async resetPassword(token, newPassword) {
    return Http.post('/auth/password/reset', {
      token,
      newPassword,
    });
  },

  async getAccountAndUpdateStore() {
    if (Store.state.user) {
      return Store.state.user;
    }
    try {
      const { data } = await Http.get('auth/account', {
        withCredentials: true,
      });
      Store.commit('authorizeUser', data);
    } catch (e) {
      Store.commit('authorizeUser', null);
    }
    return Store.state.user;
  },

  isAuthenticated() {
    return Store.state.isAuthenticated;
  },
};

export default Auth;
