<template>
  <div class="dashboard">
    <div class="mx-auto md:w-3/4 space-y-6">
      <DateRangePicker class="float-right mb-1" @input="onDateRangeChange" />
      <a
        v-if="hasData"
        class="mb-2 text-sm text-blue-500 cursor-pointer hover:underline hover:text-blue-700 float-right clear-both"
        @click="downloadAllDataAsCsv"
      >
        <font-awesome-icon
          icon="fa-regular fa-file-csv"
          class="h-4 w-4"
          aria-hidden="true"
        />
        Export all data</a
      >
      <div class="clear-both" />
      <div
        v-if="!hasData"
        class="w-full h-1/2 border-2 border-gray-300 border-dashed rounded-lg p-56 text-center hover:border-gray-400"
      >
        <font-awesome-icon
          icon="fa-regular fa-calendar-alt"
          class="text-gray-200 text-6xl"
          aria-hidden="true"
        />
        <span class="mt-2 block text-sm font-medium text-gray-500">
          No data, please select a different date range.
        </span>
      </div>
      <template v-if="hasData">
        <div class="data-wrapper">
          <div
            v-for="question of selfReportDataByQuestion"
            :key="question.questionText"
          >
            <h1 class="font-medium text-gray-500">
              {{ question.questionText }}
            </h1>
            <TemporalChart
              v-if="question.answers"
              :data="question.answers"
              :question="question.questionText"
            />
            <hr class="my-5" />
          </div>
        </div>
        <h1 class="font-medium text-gray-500">Data over time</h1>
        <SelfReportLineChart :data="combinedData" class="mb-5" />
      </template>
    </div>
  </div>
</template>

<script>
import TemporalChart from '@/components/dashboard/TemporalChart';
import DateRangePicker from '@/components/DateRangePicker.vue';
import { DateTime } from 'luxon';
import SelfReportLineChart from '@/components/dashboard/SelfReportLineChart.vue';
import { arrayToCsv, downloadCsv } from '@/services/CsvHelper';
import { mapState } from 'vuex';
export default {
  name: 'DashboardPage',
  components: { SelfReportLineChart, DateRangePicker, TemporalChart },
  data() {
    return {
      isLoading: true,
      dateRange: [
        DateTime.local().startOf('week'),
        DateTime.local().endOf('week'),
      ],
    };
  },
  computed: {
    ...mapState([
      'selfReportDtos',
      'selfReportDataByQuestion',
      'heartRateData',
    ]),
    combinedData() {
      return {
        selfReportDataByQuestion: this.selfReportDataByQuestion,
        heartRateData: this.heartRateData,
      };
    },
    hasData() {
      return this.selfReportDataByQuestion?.length > 0 && !this.isLoading;
    },
  },
  async created() {
    await this.dispatchSelfReportData();
  },
  methods: {
    async onDateRangeChange(dateRange) {
      this.dateRange = [
        DateTime.fromJSDate(dateRange[0]),
        DateTime.fromJSDate(dateRange[1]),
      ];
      await this.dispatchSelfReportData();
    },
    async dispatchSelfReportData() {
      this.isLoading = true;
      await this.$store.dispatch('fetchSelfReportData', {
        start: this.dateRange[0].toISODate(),
        end: this.dateRange[1].toISODate(),
      });

      this.isLoading = false;
    },
    downloadAllDataAsCsv() {
      // get all unique question strings
      const uniqueQuestionStrings = new Set();
      this.selfReportDtos.forEach((dto) => {
        dto.answers
          .map((a) => a.question)
          .forEach((q) => {
            uniqueQuestionStrings.add(q);
          });
      });
      const csvHeader = [
        'completedOnDeviceAt',
        'startedOnDeviceAt',
        'heartRate',
      ];
      csvHeader.push(...Array.from(uniqueQuestionStrings));
      const csvResult = [csvHeader];
      csvResult.push(
        ...this.selfReportDtos.map((d) => [
          d.completedOnDeviceAt,
          d.startedOnDeviceAt,
          d.currentHeartRate,
          ...Array.from(uniqueQuestionStrings).map((q) => {
            const answer = d.answers.find((a) => a.question === q);
            return answer ? answer.answer : '';
          }),
        ])
      );

      downloadCsv(
        arrayToCsv(csvResult),
        `songpulse_export-${this.question}.csv`
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
