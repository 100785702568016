import axios from 'axios';

import ProgressBar from '@/config/ProgressBar';

const Http = axios.create({
  baseURL: '/api/v1',
});

// Request interceptor
Http.interceptors.request.use(
  function (config) {
    config.xsrfCookieName = 'XSRF-TOKEN';
    config.xsrfHeaderName = 'X-XSRF-TOKEN';

    ProgressBar.start();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor
Http.interceptors.response.use(
  function (response) {
    ProgressBar.done();
    return response;
  },
  function (error) {
    ProgressBar.done();
    return Promise.reject(error);
  }
);

export default Http;
