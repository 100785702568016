<template>
  <input
    id="datepicker"
    v-model="mutableValue"
    class="border-solid border-2 border-gray-400 rounded-full"
    type="text"
    autocomplete="off"
  />
</template>
<script>
import { DateTime } from 'luxon';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
export default {
  name: 'DateRangePicker',
  props: {},
  data() {
    return {
      mutableValue: this.value,
      flatpickrConfig: {
        dateFormat: 'F j, Y',
        mode: 'range',
        defaultDate: [
          DateTime.local().startOf('week').toJSDate(),
          DateTime.local().endOf('week').toJSDate(),
        ],
        locale: {
          firstDayOfWeek: 1,
        },
      },
    };
  },
  mounted() {
    flatpickr('#datepicker', {
      ...this.flatpickrConfig,
      onChange: (selectedDates) => {
        if (selectedDates.length === 2) {
          this.mutableValue = selectedDates;
          this.$emit('input', selectedDates);
        }
      },
    });
  },

  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
#datepicker {
  min-width: 360px;
}
</style>
