<template>
  <AuthenticationFormContainer title="Email Verification">
    <CustomAlert
      v-if="verified"
      text="You have successfully verified your email address."
      type="success"
    />
    <CustomAlert
      v-if="errorOccurred"
      text="The provided token was invalid."
      type="error"
    />
    <router-link
      v-if="verified"
      :to="{ name: 'dashboard' }"
      class="btn btn-primary full-width relative mt-6"
    >
      Go to dashboard
    </router-link>
  </AuthenticationFormContainer>
</template>
<script>
import AuthenticationFormContainer from '@/components/AuthenticationFormContainer';
import Auth from '@/services/auth/Auth';
import CustomAlert from '@/components/CustomAlert';
export default {
  name: 'EmailVerificationPage',
  components: { CustomAlert, AuthenticationFormContainer },
  data() {
    return {
      verified: false,
      errorOccurred: false,
    };
  },
  mounted() {
    this.verifyToken();
  },

  methods: {
    async verifyToken() {
      const params = this.$route.params;
      if (params.token) {
        try {
          await Auth.verifyEmailAddress(params.token);
          this.verified = true;
          this.errorOccurred = false;
        } catch (e) {
          this.verified = false;
          this.errorOccurred = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.registration {
}
</style>
