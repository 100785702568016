<template>
  <div class="dashboard">
    <div class="min-h-full">
      <div class="bg-gray-800 pb-24">
        <header class="py-10">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold text-white">
              {{ title }}
            </h1>
          </div>
        </header>
      </div>

      <main class="-mt-24">
        <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
            <slot></slot>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardContentWrapper',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
