function arrayToCsv(array) {
  return array
    .map((row) => {
      return row
        .map((val) => (val === null ? '' : val))
        .map(String)
        .map((val) => val.replace("'", '""'))
        .map((val) => `"${val}"`)
        .join(',');
    })
    .join('\n');
}

function downloadCsv(csv, filename) {
  const csvFile = new Blob([csv], { type: 'text/csv' });
  const downloadLink = document.createElement('a');
  downloadLink.download = cleanFilename(filename);
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = 'none';
  document.body.appendChild(downloadLink);
  downloadLink.click();
}
function cleanFilename(filename) {
  return filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}

export { arrayToCsv, downloadCsv };
