<template>
  <div class="chart-container">
    <SwitchWithLabel
      v-model="showStepChart"
      label="Show Heart Rate"
      class="switch-with-label mb-3 mt-3 float-right"
      @input="onShowStepChartChanged"
    />
    <div ref="chartdiv" class="chart clear-both"></div>
  </div>
</template>
<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import SwitchWithLabel from '@/components/ui-elements/SwitchWithLabel.vue';

export default {
  name: 'SelfReportLineChart',
  components: { SwitchWithLabel },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showStepChart: false,
      root: null,
    };
  },
  async mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
  methods: {
    createChart() {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(this.$refs.chartdiv);
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          maxTooltipDistance: 0,
          pinchZoomX: true,
        })
      );

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: 'hour',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      let yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: true,
      });

      let heartRateYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          strictMinMax: true,
          opposite: true,
          renderer: yRenderer,
        })
      );

      // Add heart rate data
      const mappedHeartRateData = this.data.heartRateData.map((a) => {
        return {
          value: a.value,
          date: new Date(a.date).getTime(),
        };
      });
      let series;
      if (this.showStepChart) {
        series = chart.series.push(
          am5xy.StepLineSeries.new(root, {
            name: 'Heart Rate',
            minBulletDistance: 10,
            xAxis: xAxis,
            yAxis: heartRateYAxis,
            valueYField: 'value',
            valueXField: 'date',
            legendValueText: '{valueY}',
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'horizontal',
              labelText: '{valueY}',
            }),
          })
        );
      } else {
        series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: 'Heart Rate',
            minBulletDistance: 10,
            xAxis: xAxis,
            yAxis: heartRateYAxis,
            valueYField: 'value',
            valueXField: 'date',
            legendValueText: '{valueY}',
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: 'horizontal',
              labelText: '{valueY}',
            }),
          })
        );
      }
      series.data.setAll(mappedHeartRateData);

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      for (const questionData of this.data.selfReportDataByQuestion) {
        const mappedData = questionData.answers.map((a) => {
          return {
            value: a.value,
            date: new Date(a.date).getTime(),
          };
        });
        let series;
        if (this.showStepChart) {
          series = chart.series.push(
            am5xy.StepLineSeries.new(root, {
              name: questionData.questionText,
              minBulletDistance: 10,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: 'value',
              valueXField: 'date',
              legendValueText: '{valueY}',
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
              }),
            })
          );
        } else {
          series = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: questionData.questionText,
              minBulletDistance: 10,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: 'value',
              valueXField: 'date',
              legendValueText: '{valueY}',
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
              }),
            })
          );
        }

        series.strokes.template.setAll({
          strokeWidth: 2,
        });
        series.data.setAll(mappedData);

        series.appear();
      }

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
        })
      );
      cursor.lineY.set('visible', false);

      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      let legend = chart.bottomAxesContainer.children.push(
        am5.Legend.new(root, {
          paddingLeft: 15,
          width: am5.percent(100),
        })
      );

      // When legend item container is hovered, dim all the series except the hovered one
      legend.itemContainers.template.events.on('pointerover', function (e) {
        let itemContainer = e.target;

        // As series list is data of a legend, dataContext is series
        let series = itemContainer.dataItem.dataContext;

        chart.series.each(function (chartSeries) {
          if (chartSeries != series) {
            chartSeries.strokes.template.setAll({
              strokeOpacity: 0.15,
              stroke: am5.color(0x000000),
            });
          } else {
            chartSeries.strokes.template.setAll({
              strokeWidth: 3,
            });
          }
        });
      });

      legend.itemContainers.template.events.on('pointerout', () => {
        chart.series.each(function (chartSeries) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 1,
            strokeWidth: 1,
            stroke: chartSeries.get('fill'),
          });
        });
      });

      legend.itemContainers.template.set('width', am5.p100);
      legend.valueLabels.template.setAll({
        width: am5.p100,
        textAlign: 'right',
      });
      legend.data.setAll(chart.series.values);
      chart.appear(1000, 100);
      this.root = root;
    },
    onShowStepChartChanged(input) {
      this.showStepChart = input;
      this.createChart();
    },
  },
};
</script>

<style scoped lang="scss">
.chart {
  width: 100%;
  height: 500px;
}
</style>
