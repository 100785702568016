<template>
  <div
    class="max-w-3xl w-full mx-auto px-4 my-4 md:my-8 flex-col md:flex-row relative md:mb-32 mb-16 sm:px-6 lg:px-8"
  >
    <div class="prose">
      <h1>Study Participation Terms and Privacy Notice</h1>

      <div class="bg-white shadow sm:rounded-lg mb-8">
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900 my-0">
            Important Information for Research Participants
          </h3>
          <div class="mt-2 sm:flex sm:items-start sm:justify-between">
            <div class="text-sm text-gray-500">
              <p>
                Thank you for helping us with one of our research study
                projects. The Privacy Notice and Study Participation Terms are
                for people who are participating in, are planning to, or have
                been invited to participate in, an academic research project
                being conducted by one or more students and/or staff of the
                University of Zurich. You should read this document carefully.
              </p>
              <br />
              <p>
                We care about your privacy and we want you to be comfortable
                with how we use your personal data. This document is therefore
                designed to provide you with transparent and important
                information on how we collect, use, retain, protect and share
                your data.
              </p>
              <br />
              <p>
                By participating in our study, accessing, or using our services,
                you agree to the collection and use of information in relation
                to this policy. The Personal Information that we collect is used
                for providing and improving our services. We will not use or
                share your information with anyone except as described in this
                Privacy Notice.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="prose">
      <h1>Study Participation Terms</h1>

      <h2>1. People</h2>
      <p>
        This study is conducted as part of an ongoing Independent Study at the
        Department of Informatics at the University of Zurich. It is conducted
        by:
      </p>
      <div class="grid grid-cols-2 gap-4">
        <div>
          <h4 class="mb-2">Principal Investigators</h4>
          <ul>
            <li>Prof. Dr. Thomas Fritz (fritz@ifi.uzh.ch)</li>
          </ul>
        </div>
        <div>
          <h4 class="mb-2">Student & Developer</h4>
          <ul>
            <li>Sebastian Richner (sebastian.richner@uzh.ch)</li>
          </ul>
        </div>
      </div>
      <div class="rounded-md bg-gray-100 border border-gray-200 mt-4 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <i class="far fa-info-circle h-5 w-5 text-gray-400"></i>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <div class="m-0 text-gray-700">
              <h3 class="mt-1">Questions</h3>
              If you have any questions or concerns about the participation in a
              research project or wish further information with respect to the
              study, please contact the researchers (<a
                href="mailto:contact@yaht.app"
                class="underline font-semibold"
                >songpulse@ifi.uzh.ch</a
              >), Prof. Dr. Thomas Fritz (<a
                href="mailto:fritz@ifi.uzh.ch"
                class="underline font-semibold"
                >fritz@ifi.uzh.ch</a
              >), or Sebastian Richner (<a
                href="mailto:sebastian.richner@uzh.ch"
                class="underline font-semibold"
                >sebastian.richner@uzh.ch</a
              >).
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>2. Purpose</h2>
        <p>
          The goal of this research project is to evaluate the use of a new
          software called “SongPulse”. The study uses a web application and a
          Garmin Smartwatch application that both can be used to submit and
          automatically collect data (e.g., self-reports). These self-reports
          will then be used to train a classifier to predict stress or fatigue.
        </p>
        <p>The study aims to answer the following research question:</p>

        <ul>
          <li>
            RQ1: Can we use heart- and activity-related data (from a Garmin
            smartwatch) in combination with self-reports to create a classifier
            capable of accurately recognizing high and low stress or fatigue in
            knowledge workers?
          </li>
        </ul>
      </div>

      <h2>3. Personal Information</h2>
      <p>
        During the study, we will collect personal information about you such as
        your name, email, gender, age, and information about current or past
        employment. For our research, we will only use your anonymized data and
        no identifying information will ever be shared outside of the research
        group and the confines of this study without your explicit permission.
        All data collected will be saved in password-protected storages. The
        identifiable data will only be stored for 1 year after the study ended
        before either being de-identified or deleted.
      </p>
      <h2>4. Data, Storage & Confidentiality</h2>
      <p>
        All the data collected by the SongPulse watch app and webapp are stored
        in a password-protected database located in Europe. Both the webapp and
        the app use SSL/TLS encryption to protect the confidentiality of the
        data during transmission.
      </p>
      <p>
        The data collected with surveys will be stored and processed by a
        third-party online survey tool. Their respective terms and privacy terms
        apply. In case of recorded interviews, the audio recording will be
        transcribed by one of the researchers or may be transcribed by a
        third-party. Their respective terms and privacy terms apply. After the
        transcriptions, the audio recording will be deleted.
      </p>
      <p>
        All data will be saved on the researchers’ password-protected devices.
        You will be identified by pseudonyms in any internal or academic
        research publication or presentation. Your raw data will be used and
        seen only by researchers directly involved with this project. The
        anonymized, non-identifiable data will be stored for five years, after
        which it will be permanently deleted.
      </p>
      <h2>5. Uses of the Study Data</h2>
      <p>
        The results of this study will potentially appear in both internal and
        external academic research presentations and publications, such as
        academic journals and conference proceedings.
      </p>
    </div>
    <div class="prose mt-10">
      <h1>Privacy Notice</h1>
      <p>
        This section complements the Study Terms mentioned above and both apply.
        The Privacy Notice is intended to further detail the collection of
        information and its usage.
      </p>

      <h2>Log Data</h2>
      <p>
        While using the applications provided and used during this study, logs
        may be created automatically by one or more of our applications. These
        logs may be created by a third-party service and may be stored on their
        servers, stored locally on your devices and/or submitted automatically
        to one of our servers. These log statements and files are used to debug
        and improve our applications in case of an issue or error. This log data
        may include information, such as your device's Internet Protocol (“IP”)
        address, data related to your browser (e.g., agent, settings), device
        name, operating system, the device's configuration, the time and date,
        user account-related data, and other statistics.
      </p>

      <h2>Cookies</h2>
      <p>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
        We use cookies to make the use of our applications easier. Furthermore,
        we may use third party services and libraries that use cookies to
        collect information and improve their respective services. You have the
        option to either accept or refuse these cookies and know when a cookie
        is being sent to your device. If you choose to refuse our cookies, you
        might not be able to use your services.
      </p>

      <h2>Service Providers</h2>
      <p>
        We may employ third-party services, companies and individuals due to the
        following reasons:
      </p>
      <ul>
        <li>to facilitate our service,</li>
        <li>to provide the service on our behalf,</li>
        <li>to perform service-related services,</li>
        <li>or to assist us in analyzing how our service is used.</li>
      </ul>
      <p>
        These third-parties may have access to your Personal Information. The
        reason is to perform the tasks assigned to them on our behalf. However,
        they are obligated not to disclose or use the information for any other
        purpose.
      </p>

      <h2>Security</h2>
      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially available means of protecting it. But
        no method of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and we cannot guarantee its
        absolute security.
      </p>

      <h2>Links to Other Sites</h2>
      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h2>Children’s Privacy</h2>
      <p>
        Our studies and services do not address anyone under the age of 13. We
        do not knowingly collect personally identifiable information from
        children under 13. If you are a parent or guardian and you are aware
        that your child has provided us with personal information, please
        contact us so that we will be able to take necessary action.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We try our
        best to notify you of any changes by posting the new Privacy Policy on
        this page. These changes are effective immediately after they are posted
        on this page.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardPage',
};
</script>
<style scoped lang="scss"></style>
